<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col d-flex justify-content-center align-items-center flex-column">
        <div class="col-5 card mb-5">
          <div class="card-header d-flex justify-content-between">
            Rules
            <button
              v-if="editRuleBtnVisible"
              class="btn btn-sm btn-primary"
              @click="editRulesClickHandler()"
            >
              Edit
            </button>
          </div>
          <div class="card-body w-100 p-5">
            <div class="mb-3">
              <TextInput
                v-model="rules.ecomLowerBound"
                class="p-2"
                label="eCommerce lower bound"
                readonly
              />
              <TextInput
                v-model="rules.ecomUpperBound"
                class="p-2"
                label="eCommerce upper bound"
                readonly
              />
              <TextInput
                v-model="rules.ltmLaserLowerBound"
                class="p-2"
                label="LTM Laser lower bound"
                readonly
              />
              <TextInput
                v-model="rules.ltmLaserUpperBound"
                class="p-2"
                label="LTM Laser upper bound"
                readonly
              />
              <TextInput
                v-model="rules.ltmEmbLowerBound"
                class="p-2"
                label="LTM Embroidery lower bound"
                readonly
              />
              <TextInput
                v-model="rules.ltmEmbUpperBound"
                class="p-2"
                label="LTM Embroidery upper bound"
                readonly
              />
              <TextInput
                v-model="rules.whWarnBefore"
                class="p-2"
                label="Wholesale warning before start"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="card col-8">
          <div class="card-header d-flex justify-content-between">
            Forecast recipients
            <button
              v-if="addRecipientBtnVisible"
              class="btn btn-sm btn-primary"
              @click="addNewClickHandler()"
            >
              Add
            </button>
          </div>
          <div class="card-body p-0">
            <div
              v-if="forecastDecisionRecipients.length"
              style="height: 30em;"
            >
              <ag-grid-vue
                :columnDefs="forecastsRecipientsTableColumns"
                :defaultColDef="forecastsRecipientsTableColumnsDefaults"
                :pagination="true"
                :paginationAutoPageSize="true"
                :rowData="forecastDecisionRecipients"
                class="ag-theme-alpine h-100 w-100"
                sideBar="filters"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ForecastEditRecipientModal
      ref="forecastModal"
      :viewType="modalViewType"
    />
    <ForecastEditRuleModal
      ref="forecastRuleModal"
    />
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import { mapActions, mapGetters } from 'vuex';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import TextInput from '@/components/common/TextInput';
import ForecastEditRecipientModal from '@/components/Forecast/ForecastEditRecipientModal';
import { booleanIconCellRenderer } from '@/lib/agGridCellRenderers';
import ForecastEditRuleModal from '@/components/Forecast/ForecastEditRuleModal';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { hasPrivilege } from '@/service/userAccess';

export default {
  components: {
    AgGridVue,
    TextInput,
    ForecastEditRecipientModal,
    ForecastEditRuleModal,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer,
  },
  props: ['brand'],
  data() {
    return {
      modalViewType: null
    };
  },
  computed: {
    ...mapGetters({
      rules: 'forecast/forecastRules',
      forecastDecisionRecipients: 'forecast/forecastRecipients',
    }),
    addRecipientBtnVisible() {
      return hasPrivilege(userPrivileges.createForecastRecipient);
    },
    editRuleBtnVisible() {
      return hasPrivilege(userPrivileges.updateForecastRule);
    },
    forecastsRecipientsTableColumns() {
      return [
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            visibilityCheck() {
              return hasPrivilege(userPrivileges.createForecastRecipient);
            },
            clicked: (field) => {
              this.editClickHandler(field);
            }
          },
          width: 80
        },
        {
          headerName: 'email',
          field: 'email',
          width: 320
        },
        {
          field: 'receiveForecastDecision',
          headerName: 'Receive Forecast decisions',
          headerTooltip: 'Receive Forecast decisions',
          cellRenderer: booleanIconCellRenderer(),
          width: 150,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'receiveMismatchAlert',
          headerName: 'Receive Mismatch alerts',
          headerTooltip: 'Receive Mismatch alerts',
          cellRenderer: booleanIconCellRenderer(),
          width: 150,
          wrapText: true,
          autoHeight: true
        }
      ];
    },
    forecastsRecipientsTableColumnsDefaults() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
  },
  methods: {
    ...mapActions({
      fetchRules: 'forecast/fetchRules',
      fetchForecastDecisionRecipients: 'forecast/fetchForecastRecipients',
    }),
    async init() {
      await Promise.all([
        this.fetchRules(this.brand.id),
        this.fetchForecastDecisionRecipients(this.brand.id)
      ]);
    },
    editRulesClickHandler() {
      if (this.rules.brandId) {
        this.modalViewType = modalViewTypes.edit;
        this.$refs.forecastRuleModal.initModal(this.brand.id);
      } else {
        this.modalViewType = modalViewTypes.add;
        this.$refs.forecastRuleModal.initAddModal(this.brand.id, this.brand.name);
      }
    },
    addNewClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.forecastModal.initAddModal(this.brand.id, this.brand.name);
    },
    editClickHandler(id) {
      this.modalViewType = modalViewTypes.edit;
      this.$refs.forecastModal.initModal(id);
    },
  }
};
</script>
